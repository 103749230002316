import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { Agent } from '../agent';
import { ComplexNumber } from '../complexnumber';

import { AgentFilter } from './pipes/agentfilter.pipe';

import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { faSort } from '@fortawesome/free-solid-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faSlidersH } from '@fortawesome/free-solid-svg-icons';
import { faTh } from '@fortawesome/free-solid-svg-icons';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faMinus } from '@fortawesome/free-solid-svg-icons';

import { Sort } from '@angular/material/sort';

import { HttpClient } from '@angular/common/http';

import { OktaAuthService } from '@okta/okta-angular';

import { environment } from '../../environments/environment';

@Component({
  selector: 'app-sponsored-agents',
  templateUrl: './sponsored-agents.component.html',
  styleUrls: ['./sponsored-agents.component.css']
})

export class SponsoredAgentsComponent implements OnInit {

  userName: string;

  //font awesome icons
  faInfo = faInfo;
  faEnvelope = faEnvelope;
  faPhoneAlt = faPhoneAlt;
  faSort = faSort;
  faSearch = faSearch;
  faSlidersH = faSlidersH;
  faTh = faTh;
  faAngleDown = faAngleDown;
  faUser = faUser;
  faPlus = faPlus;
  faMinus = faMinus;
  AgentFilter = AgentFilter;

  //raw data read from DB
  agents: Agent[] = [];

  //sorted data for display on the page
  sortedAgents: Agent[] = [];

  nameFilter: string = '';
  stateFilter: number;

  showHideFilterMenu: boolean = false;
  showHideBoxMenu: boolean = false;
  showHideUserMenu: boolean = false;

  userIsLoggedIn: boolean = false;
  loading: boolean = true;

  legendOpen: boolean = false;
  activeStep: number = null;
  steps = [{
    title: 'License Verification',
    description: 'eXp verifies the joining agents License details with the state commission',
    next: 'Broker Approval'
  }, {
    title: 'Broker Approval',
    description: 'eXp reviews joining agents that have licenses not in good standing',
    next: 'Documents Review'
  }, {
    title: 'Documents Review',
    description: 'eXp reviews the ICA and works with the agent on any revisions',
    next: 'License Transfer'
  }, {
    title: 'License Transfer',
    description: 'Agent must transfer their license within the process set by their state & eXp must confirm that the transfer is complete within the Join App',
    next: 'Agent Sent to Enterprise'
  }];

  constructor(
    private http: HttpClient,
    private authService: OktaAuthService
  ) { }

  //width of display to determine which screen format to use
  displayWidth: number = 0;

  //default screen format is full page
  showFullPage: boolean = true;
  showTabletPage: boolean = false;
  showPhonePage: boolean = false;

  showLegend(state: ComplexNumber) {
    this.legendOpen = true;
    this.activeStep = state.sorttext - 2;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    //determine the width of the window in order to display the appropriate format
    this.displayWidth = window.innerWidth;

    if (this.displayWidth > 1199) {
      this.showFullPage = true;
      this.showTabletPage = false;
      this.showPhonePage = false;
    } else if (this.displayWidth > 767) {
      this.showFullPage = false;
      this.showTabletPage = true;
      this.showPhonePage = false;
    } else {
      this.showFullPage = false;
      this.showTabletPage = false;
      this.showPhonePage = true;
    }
  }

  async ngOnInit() {

    const response = await this.http.get(environment.apiEndpoint, {
      headers: {
        Authorization: await this.authService.getIdToken()
      }
    }).toPromise();

    const user = await this.authService.getUser();
    if (user.given_name && user.family_name) {
      this.userName = `${user.given_name} ${user.family_name}`;
    } else {
      this.userName = user.email;
    }

    for (var item of response as any) {
      const newAgentFromData = new Agent();
      newAgentFromData.currentstate = new ComplexNumber();
      newAgentFromData.timeinstate = new ComplexNumber();

      newAgentFromData.joinid = item.joinId;
      newAgentFromData.joinidIntegration = getAgentType(item.joinidIntegration);
      newAgentFromData.agentname = item.agentName;
      newAgentFromData.agentemail = item.agentEmail;
      newAgentFromData.agentphone = item.agentPhone;
      newAgentFromData.sponsoremail = item.sponsorEmail;
      newAgentFromData.currentstate.displaytext = item.currentStep;
      newAgentFromData.currentstate.nextDisplayText = item.nextstep;
      //TODO: Map status codes to pretty labels - this may change once there is real data
      if (item.currentStepId == "1") {
        newAgentFromData.currentstate.sorttext = 1;
      } else if (item.currentStepId == "2") {
        newAgentFromData.currentstate.sorttext = 2;
      } else if (item.currentStepId == "3") {
        newAgentFromData.currentstate.sorttext = 3;
      } else if (item.currentStepId == "4") {
        newAgentFromData.currentstate.sorttext = 4;
      } else if (item.currentStepId == "5") {
        newAgentFromData.currentstate.sorttext = 5;
      } else if (item.currentStepId == "6") {
        newAgentFromData.currentstate.sorttext = 6;
      } else {
        //NOTE: Doesn't match any known mapping code
        newAgentFromData.currentstate.displaytext = item.currentStepId;
        newAgentFromData.currentstate.sorttext = item.currentStepId;
      }
      let timestamp = item.changeTime;
      let nowdate = new Date();
      let nowtime = nowdate.getTime();

      var differencemillis = nowtime - parseInt(timestamp);
      var dayssince = Math.floor(differencemillis / (1000 * 60 * 60 * 24));

      if (dayssince == 0) {
        var updatedate = new Date(parseInt(timestamp));

        //been less than 24 hours, so now determine if it was today or yesterday
        //console.log("Comparing " + nowdate.toDateString() + " to " + updatedate.toDateString() + " which came from " + timestamp)
        if (nowdate.toDateString() == updatedate.toDateString()) {
          newAgentFromData.timeinstate.displaytext = "Today";
        } else {
          newAgentFromData.timeinstate.displaytext = "<24 hours";
        }
      } else if (dayssince == 1) {
        newAgentFromData.timeinstate.displaytext = "1 day";
      } else {
        newAgentFromData.timeinstate.displaytext = dayssince + " days";
      }

      newAgentFromData.timeinstate.sorttext = differencemillis;

      //TODO:  Do we want to change days >7 to "1 week"?

      this.agents.push(newAgentFromData);
    }

    this.sortedAgents = this.agents.slice();

    this.onResize();

    this.loading = false;
  }

  ngAfterViewInit() {
    document.addEventListener('click', this.handleClick.bind(this), true);
  }

  ngOnDestroy() {
    document.removeEventListener('click', this.handleClick, true)
  }

  @ViewChild('filterMenuDiv') filterMenu: ElementRef;
  @ViewChild('boxMenuDiv') boxMenu: ElementRef;
  @ViewChild('userMenuDiv') userMenu: ElementRef;

  handleClick({ target }) {
    if (this.showHideFilterMenu && !this.filterMenu.nativeElement.parentElement.contains(target)) {
      this.showHideFilterMenu = false;
    }
    if (this.showHideBoxMenu && !this.boxMenu.nativeElement.parentElement.contains(target)) {
      this.showHideBoxMenu = false;
    }
    if (this.showHideUserMenu && !this.userMenu.nativeElement.parentElement.contains(target)) {
      this.showHideUserMenu = false;
    }
  }

  filterByState(state: number) {
    this.showHideFilterMenu = !this.showHideFilterMenu;
    this.stateFilter = state;
  }

  async logout() {
    await this.authService.logout({
      postLogoutRedirectUri: window.location.origin,
      revokeAccessToken: false
    });
  }

  sortData(sort: Sort) {

    console.log("Sorting data.");

    const data = this.agents.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedAgents = data;
      return;
    }

    this.sortedAgents = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          {
            return compare(a.agentname, b.agentname, isAsc);
          }
        case 'step':
          {
            return compare(a.currentstate.displaytext, b.currentstate.displaytext, isAsc);
          }
        case 'time':
          {
            return compare(a.timeinstate.sorttext, b.timeinstate.sorttext, isAsc);
          }
        case 'nextstep':
          {
            return compare(a.currentstate.nextDisplayText, b.currentstate.nextDisplayText, isAsc);
          }
        case 'phone':
          {
            return compare(a.agentphone, b.agentphone, isAsc);
          }
        case 'type':
          {
            return compare(a.joinidIntegration, b.joinidIntegration, isAsc);
          }
        default: return 0;
      }
    });
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

function getAgentType(agentType: string) {
  if (agentType !== undefined) {
    return (agentType.indexOf("J") !== -1 ? "Residential" : "Commercial");
  }
}
