import {Injectable, Pipe, PipeTransform} from '@angular/core'
import {Agent} from '../../agent';

@Pipe({
  name: 'agentFilter'
})
@Injectable()
export class AgentFilter implements PipeTransform {
  transform(agents: Agent[], nameFilter: string, stateFilter: number): any {
    return agents.filter(agent =>{
      if(nameFilter && agent.agentname.toLowerCase().indexOf(nameFilter.toLowerCase()) === -1) {
        return false;
      }
      if(stateFilter && agent.currentstate.sorttext != stateFilter)  {
        return false;
      }
      return true;
    })
  }
}