<div style="height: 100%; display: flex; flex-direction: column; color: #303030;">
  <table style="width:100%; border-spacing: 0; display: flex; flex-direction: column;">
    <ng-container *ngIf="showFullPage || showTabletPage">
      <tr class="titleTableRow">
        <td style="text-align: left; width:100%;"><img style="height: 40px;" src="../../assets/images/prospect-view-logo.png"></td>
        <td id="boxLinkMenuPosition" style="text-align:right; border-right: 1px solid #dadada; border-left: 1px solid #dadada; position: relative;">
          <fa-icon style="color: #303030" id="boxLink" (click)="showHideBoxMenu =! showHideBoxMenu" [icon]="faTh"></fa-icon>
          <ng-container *ngTemplateOutlet="boxMenu"></ng-container>
        </td>
        <td style="position: relative">
          <span class="titleTableUser" (click)="showHideUserMenu =! showHideUserMenu"><a class="plainlink" style="color: #303030" href="#">{{this.userName}}&nbsp;&nbsp;<fa-icon id="userLink" [icon]="faAngleDown"></fa-icon></a></span>
          <ng-container *ngTemplateOutlet="userLinkMenu"></ng-container>
        </td>
      </tr>
    </ng-container>
    <ng-container *ngIf="showPhonePage">
      <tr class="titleTableRow">
        <td style="text-align: left; width:100%; padding-left:15px;"><img style="width: 185px;" src="../../assets/images/prospect-view-logo.png"></td>
        <td id="boxLinkMenuPosition" style="text-align:right; border-right: 1px solid #dadada; border-left: 1px solid #dadada; position: relative;">
          <fa-icon id="boxLink" (click)="showHideBoxMenu =! showHideBoxMenu" [icon]="faTh"></fa-icon>
          <ng-container *ngTemplateOutlet="boxMenu"></ng-container>
        </td>
        <td style="position: relative">
          <span class="titleTableUser" (click)="showHideUserMenu =! showHideUserMenu"><a class="iconlink" href="#"><fa-icon id="userLink" [icon]="faUser"></fa-icon></a></span>
          <ng-container *ngTemplateOutlet="userLinkMenu"></ng-container>
        </td>
      </tr>
    </ng-container>
  </table>
  <ng-container *ngIf="(sortedAgents | agentFilter:nameFilter:stateFilter) as filteredAgents">
    <div class="funnel-page" style="border-spacing: 0; display: flex; flex-direction: column; height: 100%;" >
      <div class="bg"></div>
      <div style="display: flex; flex-direction: column; min-height: max-content;">
        <div style="font-weight: 600; font-family: Open Sans, sans-serif; font-size: 26px; color: white; margin-top: 20px">
          Applicants in Joining
        </div>
        <div style="font-family: Open Sans, sans-serif; font-size: 16px; color: white; margin: 10px 0">
          Only US joining agents will be shown here at this time
        </div>
        <div style="display: flex; align-items: center; flex-wrap: wrap; margin-bottom: 20px">
          <div class="searchbox" style="margin: 20px 20px 20px 0;">
            <input type="text" class="searchinput" placeholder="Name Search" [(ngModel)]="nameFilter">
            <div style="float: right;">
              <fa-icon [icon]="faSearch" style="font-size: 16px"></fa-icon>
            </div>
          </div>
          <div style="flex: 1; display: flex; align-items: center;">
            <div style="color: white; position: relative; margin-right: 20px;">
              <div (click)="showHideFilterMenu =! showHideFilterMenu">
                <fa-icon class="slider-icon" [icon]="faSlidersH"></fa-icon>
              </div>
              <ng-container *ngTemplateOutlet="filterMenu"></ng-container>
            </div>
            <div *ngIf="showFullPage" style="flex: 1"></div>
            <div style="text-align: right; font-family:Open Sans, sans-serif; font-size:18px; color:white;">Results:&nbsp;{{filteredAgents.length}}</div>
          </div>
        </div>
      </div>
      <div *ngIf="showFullPage" class="agenttable" style="flex: 1; margin-bottom: 30px">
        <table class="mat-sort" style="width: 100%" matSort (matSortChange)="sortData($event)">
          <tr>
            <th class="theader" mat-sort-header="name">Name</th>
            <th class="theader" mat-sort-header="type">Type</th>
            <th class="theader" mat-sort-header="step">Current Step</th>
            <th class="theader" mat-sort-header="nextstep">Next Step</th>
            <th class="theader" mat-sort-header="time">Time in step</th>
            <th class="theader" mat-sort-header="phone">Phone</th>
            <!--<th class="theader" mat-sort-header="email">Email</th>-->
            <!--<th class="theader">Actions</th>-->
          </tr>
          <tr *ngIf="sortedAgents.length == 0 && !loading">
            <td class="tdata" colspan="5">
              You do not have any agents in the joining process. Please check back later.
            </td>
          </tr>
          <tr *ngFor="let agent of filteredAgents">
            <td class="tname">{{agent.agentname}}</td>
            <td class="tdata">{{agent.joinidIntegration}}</td>
            <td class="tdata">{{agent.currentstate.displaytext}}</td>
            <td class="tdata">{{agent.currentstate.nextDisplayText}}</td>
            <td class="tdata">{{agent.timeinstate.displaytext}}</td>
            <td class="tdata"><a href="tel:{{agent.agentphone}}" title="Dial Number">{{agent.agentphone}}</a></td>
            <!--<td class="ticons">
              <ul class="ticonlist">
                <li>
                  <a href="#" (click)="showLegend(agent.currentstate)" title="See step legend details">
                    <fa-icon [icon]="faInfo" class="ticon"></fa-icon>
                  </a>
                </li>
                <li>
                  <a href="mailto:{{agent.agentemail}}" title="Email">
                    <fa-icon [icon]="faEnvelope" class="ticon"></fa-icon>
                  </a>
                </li>
                <li>
                  <a href="tel:{{agent.agentphone}}" title="Dial Number">
                    <fa-icon [icon]="faPhoneAlt" class="ticon"></fa-icon>
                  </a>
                </li>
              </ul>
            </td>-->
          </tr>
        </table>
      </div>
      <table *ngIf="showPhonePage" class="agenttable-tablet">
        <tr *ngFor="let agent of filteredAgents">
          <td>
            <table style="width:100%;" class="agenttable-tablet-card-right">
              <tr>
                <td>{{agent.agentname}}</td>
                <td style="text-align: right; font-weight:600">{{agent.timeinstate.displaytext}}</td>
              </tr>
              <tr><td style="font-weight:400; color:#777;">{{agent.currentstate.displaytext}}</td></tr>
              <tr><td>&nbsp;</td></tr>
              <tr>
                <td colspan="2" style="text-align: right;">
                  <ul class="ticonlist">
                    <li>
                      <a href="#" (click)="showLegend(agent.currentstate)" title="See step legend details">
                        <fa-icon [icon]="faInfo" class="ticon"></fa-icon>
                      </a>
                    </li>
                    <li>
                      <a href="mailto:{{agent.agentemail}}" title="Email">
                        <fa-icon [icon]="faEnvelope" class="ticon"></fa-icon>
                      </a>
                    </li>
                    <li>
                      <a href="tel:{{agent.agentphone}}" title="Dial Number">
                        <fa-icon [icon]="faPhoneAlt" class="ticon"></fa-icon>
                      </a>
                    </li>
                  </ul>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      <table *ngIf="showTabletPage" class="agenttable-tablet">
        <tr *ngFor="let agent of filteredAgents; let i = index">
          <ng-container *ngIf="i % 2 == 0" style="width:100%;">
            <td style="width:49%;">
              <table style="width:100%;" class="agenttable-tablet-card-left">
                <tr>
                  <td>{{agent.agentname}}</td>
                  <td style="text-align: right; font-weight:600">{{agent.timeinstate.displaytext}}</td>
                </tr>
                <tr><td style="font-weight:400; color:#777;">{{agent.currentstate.displaytext}}</td></tr>
                <tr><td>&nbsp;</td></tr>
                <tr>
                  <td  colspan="2" style="text-align: right;">
                    <ul class="ticonlist">
                      <li>
                        <a href="#" (click)="showLegend(agent.currentstate)" title="See step legend details">
                          <fa-icon [icon]="faInfo" class="ticon"></fa-icon>
                        </a>
                      </li>
                      <li>
                        <a href="mailto:{{agent.agentemail}}" title="Email">
                          <fa-icon [icon]="faEnvelope" class="ticon"></fa-icon>
                        </a>
                      </li>
                      <li>
                        <a href="tel:{{agent.agentphone}}" title="Dial Number">
                          <fa-icon [icon]="faPhoneAlt" class="ticon"></fa-icon>
                        </a>
                      </li>
                    </ul>
                  </td>
                </tr>
              </table>
            </td>
            <td style="width:15px;"></td>
            <td *ngIf="i + 1 < filteredAgents.length" style="width:49%;">
              <table style="width:100%;" class="agenttable-tablet-card-right">
                <tr>
                  <td>{{filteredAgents[i+1].agentname}}</td>
                  <td style="text-align: right; font-weight:600">{{filteredAgents[i+1].timeinstate.displaytext}}</td>
                </tr>
                <tr><td style="font-weight:400; color:#777;">{{filteredAgents[i+1].currentstate.displaytext}}</td></tr>
                <tr><td>&nbsp;</td></tr>
                <tr>
                  <td colspan="2" style="text-align: right;">
                    <ul class="ticonlist">
                      <li>
                        <a href="#" (click)="showLegend(filteredAgents[i+1].currentstate)" title="See step legend details">
                          <fa-icon [icon]="faInfo" class="ticon"></fa-icon>
                        </a>
                      </li>
                      <li>
                        <a href="mailto:{{filteredAgents[i+1].agentemail}}" title="Email">
                          <fa-icon [icon]="faEnvelope" class="ticon"></fa-icon>
                        </a>
                      </li>
                      <li>
                        <a href="tel:{{filteredAgents[i+1].agentphone}}" title="Dial Number">
                          <fa-icon [icon]="faPhoneAlt" class="ticon"></fa-icon>
                        </a>
                      </li>
                    </ul>
                  </td>
                </tr>
              </table>
            </td>
          </ng-container>
        </tr>
      </table>
    </div>
  </ng-container>
  <ng-template #filterMenu>
    <div #filterMenuDiv id="filterMenu" [style.visibility]="showHideFilterMenu ? 'visible' : 'hidden'" class="filterMenu">
      <span class="menuHeader">Filter Only:</span>
      <ul>
        <li><input type="radio" id="account-created" name="filter-state" value="1" (click)="filterByState(1)">Account Created</li>
        <li><input type="radio" id="completed-application" name="filter-state" value="2" (click)="filterByState(2)">Completed Application</li>
        <li><input type="radio" id="license-verification" name="filter-state" value="3" (click)="filterByState(3)">License Verification</li>
        <li><input type="radio" id="broker-approval" name="filter-state" value="4" (click)="filterByState(4)">Broker Approval</li>
        <li><input type="radio" id="document-review" name="filter-state" value="5" (click)="filterByState(5)">Document Review</li>
        <li><input type="radio" id="license-transfer" name="filter-state" value="6" (click)="filterByState(6)">License Transfer</li>
        <li><input type="radio" id="all" name="filter-state" value="0" (click)="filterByState(0)">All States</li>
      </ul>
    </div>
  </ng-template>
  <ng-template #boxMenu>
    <div #boxMenuDiv id="boxMenu" [style.visibility]="showHideBoxMenu ? 'visible' : 'hidden'" class="littlePopupMenu">
      <div class="title">Your Apps</div>
      <a href="https://www.expenterprise.com">&nbsp;&nbsp;Enterprise</a>
    </div>
  </ng-template>
  <ng-template #userLinkMenu>
    <div #userMenuDiv id="userMenu" [style.visibility]="showHideUserMenu ? 'visible' : 'hidden'" class="littlePopupMenu">
      <a href="#" (click)="logout()">Logout</a>
    </div>
  </ng-template>
  <div *ngIf="legendOpen" class="popupBackground" (click)="legendOpen = false"></div>
  <div *ngIf="legendOpen" class="legendPopup">
    <div class="container">
      <h1 class="title"><strong>Applicant&nbsp;Steps and&nbsp;Details</strong></h1>
      <ng-container *ngFor="let step of steps; index as i" [ngTemplateOutlet]="stepTemplate" [ngTemplateOutletContext]="{$implicit: step, i: i}"></ng-container>
    </div>
  </div>
  <ng-template #stepTemplate let-step let-i="i">
    <div class="step" [ngClass]="{'active': activeStep === i}">
      <div class="stepTitle" (click)="activeStep === i ? activeStep = null : activeStep = i">
        <h2>{{step.title}}</h2>
        <fa-icon [icon]="activeStep === i ? faMinus : faPlus"></fa-icon>
      </div>
      <div class="stepContent">
        <div>{{step.description}}</div>
        <div>
          <strong>Next step</strong> is {{step.next}}
        </div>
      </div>
    </div>
  </ng-template>
</div>
