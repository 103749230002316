import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { SponsoredAgentsComponent } from './sponsored-agents/sponsored-agents.component';
import { MatSortModule } from '@angular/material/sort';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AgentFilter } from './sponsored-agents/pipes/agentfilter.pipe';

import { OKTA_CONFIG, OktaAuthModule, OktaAuthGuard, OktaCallbackComponent, OktaAuthService } from '@okta/okta-angular';

import { environment } from '../environments/environment';

import * as AWS from 'aws-sdk';

AWS.config.region = 'us-east-1';

const oktaConfig = {
  clientId: environment.clientId,
  issuer: environment.issuer,
  redirectUri: `${window.location.origin}/callback/`,
  scopes: ['email', 'profile'],
  pkce: false
}

const appRoutes: Routes = [
  {
    path: '',
    component: SponsoredAgentsComponent,
    canActivate: [OktaAuthGuard]
  },
  {
    path: 'callback',
    component: OktaCallbackComponent
  }
]

@NgModule({
  declarations: [
    AppComponent,
    SponsoredAgentsComponent,
    AgentFilter
  ],
  imports: [
    BrowserModule,
    FontAwesomeModule,
    MatSortModule,
    BrowserAnimationsModule,
    FormsModule,
    OktaAuthModule,
    RouterModule.forRoot(appRoutes),
    HttpClientModule
  ],
  providers: [
    { provide: OKTA_CONFIG, useValue: oktaConfig }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
