import { ComplexNumber } from './complexnumber';
import { ComplexString } from './complexstring';

export class Agent {
    joinid: string;
    joinidIntegration: string;
    agentname: string;
    agentemail: string;
    agentphone: string;
    sponsoremail: string;
    currentstate: ComplexNumber;
    timeinstate: ComplexNumber;
  }
